import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import type { TableProps } from 'antd'
import { notification } from 'antd'
import { Button, Modal, Row, Typography, Input, Table } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsAssignmentSelector } from '../../containers/SettingsAssignment/selectors.ts'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/SettingsAssignment/slice.ts'
import type { DaDataSuggestion } from 'react-dadata/dist/types'
import type { DaDataAddress } from 'react-dadata'
import { AddressSuggestions } from 'react-dadata'
import {
    CheckOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined
} from '@ant-design/icons'
import * as iuliia from 'iuliia'
import AreaSelector from '../../containers/AreaSelector'

const { Title, Text, Paragraph } = Typography

interface IArea {
    area_id: string
    title: string
}

export function AssignmentWizardModal(): ReactElement {
    const [selectedAddress, setSelectedAddress] =
        useState<DaDataSuggestion<DaDataAddress>>()
    const [isAreasSelectorModal, setIsAreasSelectorModal] = useState(false)
    const [areas, setAreas] = useState<IArea[]>([])
    const [shortTransliterationCity, setShortTransliterationCity] = useState('')

    const [api, contextHolder] = notification.useNotification()

    const dispatch = useDispatch()

    const { selectedAssignmentItem, isModalWizardOpen, isProcessWizard } =
        useAppSelector(settingsAssignmentSelector)

    useEffect(() => {
        if (selectedAssignmentItem) {
            setSelectedAddress({
                // @ts-ignore
                data: {
                    city: selectedAssignmentItem.title,
                    fias_id: selectedAssignmentItem.fias_id,
                    kladr_id: selectedAssignmentItem.kladr_id
                }
            })
            setAreas(
                selectedAssignmentItem.areas.map(area => ({
                    title: area.name,
                    area_id: area.area_id
                }))
            )
            setShortTransliterationCity(selectedAssignmentItem.short_slug ?? '')
        }
    }, [selectedAssignmentItem])

    const resetFields = () => {
        setSelectedAddress(undefined)
        setAreas([])
        setShortTransliterationCity('')
    }

    const columns: TableProps<IArea>['columns'] = [
        {
            title: 'Регион',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: '',
            dataIndex: '',
            key: 'remove',
            align: 'end',
            render: ({ area_id }) => (
                <Button
                    danger
                    type={'primary'}
                    icon={<DeleteOutlined />}
                    onClick={() =>
                        setAreas(prev =>
                            prev.filter(area => area.area_id !== area_id)
                        )
                    }
                />
            )
        }
    ]

    const handleSendForm = () => {
        if (selectedAddress?.data.city) {
            if (selectedAssignmentItem) {
                dispatch(
                    actions.edit({
                        dadata_area_id: selectedAssignmentItem.dadata_area_id,
                        form: {
                            name: selectedAssignmentItem.title,
                            short_slug: shortTransliterationCity,
                            areas: areas.map(area => ({
                                area_id: area.area_id
                            }))
                        }
                    })
                )
            } else {
                dispatch(
                    actions.create({
                        title: selectedAddress.data.city,
                        slug: iuliia
                            .translate(
                                selectedAddress.data.city as string,
                                iuliia.YANDEX_MAPS
                            )
                            .toLowerCase(),
                        short_slug: shortTransliterationCity,
                        fias_id: selectedAddress.data.fias_id ?? undefined,
                        kladr_id: selectedAddress.data.kladr_id ?? undefined,
                        areas: areas.map(area => ({ area_id: area.area_id }))
                    })
                )
            }
        }
    }

    const handleCloseModal = () => {
        dispatch(actions.toggleModalWizardOpen(false))
    }

    return (
        <Modal
            open={isModalWizardOpen}
            title={
                selectedAssignmentItem
                    ? 'Редактирование присвоение города'
                    : 'Присовоить город'
            }
            onCancel={handleCloseModal}
            afterClose={resetFields}
            okButtonProps={{
                style: {
                    display: 'none'
                }
            }}
            cancelButtonProps={{
                style: {
                    display: 'none'
                }
            }}
        >
            {selectedAddress ? (
                <Row justify={'space-between'}>
                    <Title level={3} style={{ cursor: 'default', margin: '0' }}>
                        {selectedAddress.data.city}
                    </Title>
                    <Button
                        danger
                        icon={<EditOutlined />}
                        onClick={() => setSelectedAddress(undefined)}
                    />
                </Row>
            ) : (
                <AddressSuggestions
                    token={process.env.DADATA_KEY ?? ''}
                    value={selectedAddress}
                    onChange={setSelectedAddress}
                    filterLanguage={'ru'}
                    filterFromBound={'city'}
                    filterToBound={'city'}
                    inputProps={{
                        placeholder: 'Начните вводить название города'
                    }}
                />
            )}
            {selectedAddress ? (
                <div style={{ marginTop: 10 }}>
                    <div style={{ marginBottom: 5 }}>
                        <span>{'Транслитерация города'}</span>
                        <Text code>
                            {iuliia
                                .translate(
                                    selectedAddress.data.city as string,
                                    iuliia.YANDEX_MAPS
                                )
                                .toLowerCase()}
                        </Text>
                    </div>
                    <div style={{ marginBottom: 5 }}>
                        <span>{'FIAS'}</span>
                        <Text code>{selectedAddress.data.fias_id}</Text>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <span>{'KLADR'}</span>
                        <Text code>{selectedAddress.data.kladr_id}</Text>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <span>{'Сокращенная транслитерация города'}</span>
                        <Input
                            placeholder={'Сокращенная транслитерация города'}
                            value={shortTransliterationCity}
                            onChange={event =>
                                setShortTransliterationCity(event.target.value)
                            }
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}
                    >
                        <div style={{ marginBottom: 5 }}>{'Регионы'}</div>
                        {areas.length > 0 ? (
                            <Table
                                style={{ width: '100%', marginBottom: 20 }}
                                rowKey={'area_id'}
                                columns={columns}
                                dataSource={areas}
                                pagination={false}
                            />
                        ) : null}
                    </div>
                    <Row justify={'space-between'} align={'middle'}>
                        <Button
                            size={'small'}
                            icon={<PlusOutlined />}
                            onClick={() => setIsAreasSelectorModal(true)}
                        >
                            {'Добавить регионы'}
                        </Button>
                        <Button
                            icon={<CheckOutlined />}
                            type={'primary'}
                            disabled={areas.length === 0}
                            loading={isProcessWizard}
                            onClick={handleSendForm}
                        >
                            {`${selectedAssignmentItem ? 'Редактировать' : 'Создать'} присвоение города`}
                        </Button>
                    </Row>
                </div>
            ) : (
                <Paragraph style={{ marginTop: 10 }} type={'secondary'}>
                    {'Для создания необходимо выбрать город'}
                </Paragraph>
            )}
            <AreaSelector
                showModal={isAreasSelectorModal}
                onClose={() => setIsAreasSelectorModal(false)}
                onAdd={data => {
                    const someAreas = areas.filter(area =>
                        data.some(item => item.area_id === area.area_id)
                    )
                    if (someAreas.length > 0) {
                        api.open({
                            message: 'Ошибка',
                            description: `Регионы уже добавлены: ${someAreas.map(area => area.title).join(',')}`,
                            type: 'error',
                            placement: 'top'
                        })
                    } else {
                        setAreas(prev => [...data, ...prev])
                        setIsAreasSelectorModal(false)
                    }
                }}
            />
            {contextHolder}
        </Modal>
    )
}
