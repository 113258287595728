import type { Reducer } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
import type { Middleware } from 'redux-saga'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootSaga from '../sagas'
import { rootReducer } from './reducers'

export const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        'companies',
        'events',
        'company',
        'companyWizard',
        'eventWizard',
        'userSelector',
        'businessUserSelector',
        'regionSelector',
        'areaSelector',
        'companyPropertySelector',
        'bannersBlock',
        'contentPage',
        'promoCodes',
        'promoCodesAgents',
        'promoCodesReport',
        'advertising',
        'advertisingWizard',
        'myCompaniesSelector',
        'categorySelector',
        'postCategorySelector',
        'companyCategoriesSelector',
        'blog',
        'blogWizard',
        'settingsTags',
        'tagsSelector',
        'regionTreeSelector',
        'citySelector',
        'settingsCoverage',
        'settingsAssignment',
        'balance'
    ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer as Reducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
        if (process.env.APP_ENV !== 'production') {
            const logger = createLogger({ collapsed: true }) as Middleware
            return getDefaultMiddleware({ serializableCheck: false }).concat(
                sagaMiddleware,
                logger
            )
        }
        return getDefaultMiddleware({ serializableCheck: false }).concat(
            sagaMiddleware
        )
    }
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
