import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { actions } from './slice.ts'
import * as api from './api.ts'
import { formatApiError, sagaNotificationError } from '../../utils/helpers.ts'
import type {
    CreatePayload,
    EditPayload,
    FetchPayload,
    IAssignmentItem,
    RemovePayload,
    SynchronizePayload
} from './types.ts'
import { settingsAssignmentSelector } from './selectors.ts'
import type { IMeta } from '../Blog/types.ts'

export function* handleFetch(action: { payload: FetchPayload }): SagaIterator {
    try {
        const { data } = yield call(
            api.fetch,
            action.payload.page,
            action.payload.pageSize
        )
        yield put(actions.fetchSuccess({ data: data.data, meta: data.meta }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchHandleFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleSynchronize(action: {
    payload: SynchronizePayload
}): SagaIterator {
    const dadataAreaId = action.payload.dadata_area_id
    try {
        yield call(api.synchronize, dadataAreaId)
        yield put(actions.synchronizeSuccess({ dadata_area_id: dadataAreaId }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.synchronizeFailure({
                dadata_area_id: dadataAreaId,
                error: message
            })
        )
    }
}

export function* watchHandleSynchronize(): SagaIterator {
    yield takeEvery(actions.synchronize, handleSynchronize)
}

export function* handleEdit(action: { payload: EditPayload }): SagaIterator {
    try {
        yield call(api.edit, action.payload)
        yield put(actions.editSuccess())
        const { meta }: { meta: IMeta } = yield select(
            settingsAssignmentSelector
        )
        if (meta) {
            yield put(
                actions.fetch({
                    page: meta.currentPage,
                    pageSize: meta.perPage
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.editFailure({
                error: message
            })
        )
    }
}

export function* watchHandleEdit(): SagaIterator {
    yield takeEvery(actions.edit, handleEdit)
}

export function* handleCreate(action: {
    payload: CreatePayload
}): SagaIterator {
    try {
        yield call(api.create, action.payload)
        yield put(actions.createSuccess())
        const { meta }: { meta: IMeta } = yield select(
            settingsAssignmentSelector
        )
        if (meta) {
            yield put(
                actions.fetch({
                    page: meta.currentPage,
                    pageSize: meta.perPage
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createFailure({
                error: message
            })
        )
    }
}

export function* watchHandleCreate(): SagaIterator {
    yield takeEvery(actions.create, handleCreate)
}

export function* handleRemove(action: {
    payload: RemovePayload
}): SagaIterator {
    try {
        yield call(api.remove, action.payload.dadata_area_id)
        yield put(actions.removeSuccess())
        const { data, meta }: { data: IAssignmentItem[]; meta: IMeta } =
            yield select(settingsAssignmentSelector)
        if (meta) {
            const page =
                data.length === 1 && meta.currentPage > 1
                    ? meta.currentPage - 1
                    : meta.currentPage
            yield put(
                actions.fetch({
                    page,
                    pageSize: meta.perPage
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeFailure({
                error: message
            })
        )
    }
}

export function* watchHandleRemove(): SagaIterator {
    yield takeEvery(actions.remove, handleRemove)
}

export default function* watchSettingsAssignment(): SagaIterator {
    yield all([
        fork(watchHandleFetch),
        fork(watchHandleCreate),
        fork(watchHandleSynchronize),
        fork(watchHandleEdit),
        fork(watchHandleRemove)
    ])
}
