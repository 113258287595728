import type { Action } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'

// Reducers
import application from '../containers/Application/slice'
import authorization from '../containers/Authorization/slice'
import dashboard from '../containers/Dashboard/slice'
import company from '../containers/Company/slice'
import companies from '../containers/Companies/slice'
import events from '../containers/Events/slice'
import users from '../containers/Users/slice'
import companyWizard from '../containers/CompanyWizard/slice'
import eventWizard from '../containers/EventWizard/slice'
import userSelector from '../containers/UserSelector/slice'
import businessUserSelector from '../containers/BusinessUserSelector/slice'
import regionSelector from '../containers/RegionSelector/slice'
import areaSelector from '../containers/AreaSelector/slice'
import franchiseeSelector from '../containers/FranchiseeSelector/slice'
import companyPropertySelector from '../containers/CompanyPropertySelector/slice'
import companySelector from '../containers/CompanySelector/slice'
import bannersBlock from '../containers/BannersBlock/slice'
import contentPage from '../containers/ContentPage/slice'
import promoCodes from '../containers/PromoCodes/slice'
import promoCodesAgents from '../containers/PromoCodesAgents/slice'
import promoCodesReport from '../containers/PromoCodesReport/slice'
import advertising from '../containers/Advertising/slice'
import advertisingWizard from '../containers/AdvertisingWizard/slice'
import myCompaniesSelector from '../containers/MyCompaniesSelector/slice'
import categorySelector from '../containers/CategorySelector/slice'
import postCategorySelector from '../containers/PostCategorySelector/slice'
import companyCategoriesSelector from '../containers/CompanyCategoriesSelector/slice'
import blog from '../containers/Blog/slice'
import blogWizard from '../containers/BlogWizard/slice'
import settingsTags from '../containers/SettingsTags/slice'
import tagsSelector from '../containers/TagsSelector/slice'
import regionTreeSelector from '../containers/RegionTreeSelector/slice'
import citySelector from '../containers/CitySelector/slice'
import settingsCoverage from '../containers/SettingsCoverage/slice'
import settingsAssignment from '../containers/SettingsAssignment/slice'
import balance from '../containers/Balance/slice'

const appReducer = combineReducers({
    application,
    authorization,
    dashboard,
    companies,
    events,
    users,
    company,
    companyWizard,
    eventWizard,
    userSelector,
    businessUserSelector,
    regionSelector,
    areaSelector,
    franchiseeSelector,
    companyPropertySelector,
    companySelector,
    bannersBlock,
    contentPage,
    promoCodes,
    promoCodesAgents,
    promoCodesReport,
    advertising,
    advertisingWizard,
    myCompaniesSelector,
    categorySelector,
    postCategorySelector,
    companyCategoriesSelector,
    blog,
    blogWizard,
    settingsTags,
    tagsSelector,
    regionTreeSelector,
    citySelector,
    settingsCoverage,
    settingsAssignment,
    balance
})

export const rootReducer = (
    state: ReturnType<typeof appReducer>,
    action: Action
) => {
    if (action.type === 'authorization/logOutSuccess') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}
