import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload,
    ChangeMultiplePublishedFailurePayload,
    ChangeMultiplePublishedPayload,
    ChangeMultiplePublishedSuccessPayload,
    ChangeMultipleBlockingPayload,
    ChangeMultipleBlockingSuccessPayload,
    ChangeMultipleBlockingFailurePayload,
    DownloadExcelPayload,
    DownloadExcelSuccessPayload,
    DownloadExcelFailurePayload
} from './types'

const initialState: InitialStateType = {
    data: [],
    meta: null,
    isFetching: false,
    isProcessChangeMultiplePublishing: false,
    isProcessChangeMultipleBlocking: false,
    isDownloadExcel: false,
    error: null
}

const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        changeMultiplePublished(
            state: InitialStateType,
            _action: PayloadAction<ChangeMultiplePublishedPayload>
        ) {
            state.isProcessChangeMultiplePublishing = true
        },
        changeMultiplePublishedSuccess(
            state: InitialStateType,
            _action: PayloadAction<ChangeMultiplePublishedSuccessPayload>
        ) {
            state.isProcessChangeMultiplePublishing = false
        },
        changeMultiplePublishedFailure(
            state: InitialStateType,
            action: PayloadAction<ChangeMultiplePublishedFailurePayload>
        ) {
            state.isProcessChangeMultiplePublishing = false
            state.error = action.payload.error
        },
        changeMultipleBlocked(
            state: InitialStateType,
            _action: PayloadAction<ChangeMultipleBlockingPayload>
        ) {
            state.isProcessChangeMultipleBlocking = true
        },
        changeMultipleBlockedSuccess(
            state: InitialStateType,
            _action: PayloadAction<ChangeMultipleBlockingSuccessPayload>
        ) {
            state.isProcessChangeMultipleBlocking = false
        },
        changeMultipleBlockedFailure(
            state: InitialStateType,
            action: PayloadAction<ChangeMultipleBlockingFailurePayload>
        ) {
            state.isProcessChangeMultipleBlocking = false
            state.error = action.payload.error
        },
        downloadExcel(
            state: InitialStateType,
            _action: PayloadAction<DownloadExcelPayload>
        ) {
            state.isDownloadExcel = true
        },
        downloadExcelSuccess(
            state: InitialStateType,
            _action: PayloadAction<DownloadExcelSuccessPayload>
        ) {
            state.isDownloadExcel = false
        },
        downloadExcelFailure(
            state: InitialStateType,
            action: PayloadAction<DownloadExcelFailurePayload>
        ) {
            state.isDownloadExcel = false
            state.error = action.payload.error
        }
    }
})

export const actions = companiesSlice.actions

export default companiesSlice.reducer
