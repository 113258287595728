import type { AxiosResponse } from 'axios'
import { get, post } from '../../utils/api'
import type { ICompany } from './types'
import { ignoreKeys } from '../../utils/helpers'
import type { Key } from 'react'
import { store } from '../../store'
import { applicationSelector } from '../Application/selectors.ts'

export const fetch = (
    page: number,
    page_size: number,
    filter: Record<string, string> = {}
): Promise<AxiosResponse<ICompany[]>> => {
    const filterParam: Record<string, string> = {}

    const state = store.getState()
    const { companyProperties } = applicationSelector(state)

    for (const key in filter) {
        if (key === 'subscription_range') {
            const [from, to] = filter[key].split(',')

            const regExp = /(\d{4})(\d{2})(\d{2})/

            const formatFrom = from.replace(regExp, '$3.$2.$1')
            const formatTo = to.replace(regExp, '$3.$2.$1')

            filterParam[`filter[${key}]`] = `${formatFrom}-${formatTo}`

            continue
        }

        if (ignoreKeys.includes(key)) {
            continue
        }

        const hasCompanyProperty = companyProperties.some(
            property => property.key === key
        )

        if (hasCompanyProperty) {
            if (filterParam['filter[property_option]']) {
                filterParam['filter[property_option]'] =
                    `${filterParam['filter[property_option]']},${key}:${filter[key]}`
            } else {
                filterParam['filter[property_option]'] = `${key}:${filter[key]}`
            }
        } else {
            filterParam[`filter[${key}]`] = filter[key].includes('{d}')
                ? filter[key].split('{d}')[1]
                : filter[key]
        }
    }

    return get<ICompany[]>('/admin/companies', {
        page,
        page_size,
        include:
            'eventsCount,businessAccount,mainImage,companyCategories,activePlanSubscription,logo',
        sort: '-created_at',
        append: 'cached_displayed',
        ...filterParam
    })
}

export const changeMultiplePublished = (
    company_ids: Key[],
    published: boolean
): Promise<AxiosResponse<void>> =>
    post<void>('/admin/companies/multiply/publish', {
        company_ids,
        published
    })

export const changeMultipleBlocked = (
    company_ids: Key[],
    blocked: boolean
): Promise<AxiosResponse<void>> =>
    post<void>('/admin/companies/multiply/change-status', {
        company_ids,
        status: blocked ? 'inactive' : 'active'
    })

export const downloadExcel = async (
    page: number,
    page_size: number,
    filter: Record<string, string>
): Promise<void> => {
    const filterParam: Record<string, string> = {}

    for (const key in filter) {
        filterParam[`filter[${key}]`] = filter[key].includes('{d}')
            ? filter[key].split('{d}')[1]
            : filter[key]
    }

    const response = await get(
        '/admin/companies/export',
        {
            page,
            page_size,
            ...filterParam
        },
        {
            responseType: 'blob'
        }
    )

    const timestamp = new Date().getTime()
    const fileName = `users-${timestamp}.xlsx`

    const url = window.URL.createObjectURL(
        new Blob([response.data as BlobPart])
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()

    link.parentNode?.removeChild(link)
}
