import type { ReactElement } from 'react'
import { useEffect } from 'react'
import {
    Button,
    Divider,
    Pagination,
    Popconfirm,
    Row,
    Space,
    Table,
    Typography
} from 'antd'
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    SyncOutlined
} from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsAssignmentSelector } from './selectors.ts'
import { useSearchParams } from 'react-router-dom'
import { ignoreKeys } from '../../utils/helpers.ts'
import type { ColumnsType } from 'antd/es/table'
import type { IAssignmentItem } from './types.ts'
import { AssignmentWizardModal } from '../../components/AssignmentWizardModal'

const { Title } = Typography

const columns = (
    onEditCoverage: (
        type: 'update' | 'edit' | 'remove',
        dadata_area_id: string
    ) => void,
    isProcessSynchronizeIds: string[]
): ColumnsType<IAssignmentItem> => [
    {
        title: 'Город',
        dataIndex: 'title',
        key: 'title'
    },
    {
        title: 'Транслитерированное название',
        dataIndex: 'slug',
        key: 'slug',
        align: 'center'
    },
    {
        title: 'Короткое транслитерированное название',
        dataIndex: 'short_slug',
        key: 'short_slug',
        align: 'center',
        render: value =>
            value ? (
                <Typography style={{ fontWeight: '700' }}>{value}</Typography>
            ) : (
                <Typography style={{ color: 'red' }}>
                    {'Не заполнено'}
                </Typography>
            )
    },
    {
        title: '',
        dataIndex: '',
        key: 'nav',
        width: 200,
        align: 'center',
        render: (data: IAssignmentItem) => (
            <Space size={'middle'}>
                <Popconfirm
                    title={'Синхронизация города с компаниями и предложениями'}
                    description={
                        'Вы уверены, что хотите запустить синхронизацию города?'
                    }
                    onConfirm={() =>
                        onEditCoverage('update', data.dadata_area_id)
                    }
                    okText={'Запустить'}
                    cancelText={'Отмена'}
                >
                    <Button
                        loading={isProcessSynchronizeIds.some(
                            id => id === data.dadata_area_id
                        )}
                        type={'primary'}
                        icon={<SyncOutlined />}
                    />
                </Popconfirm>
                <Button
                    onClick={() => onEditCoverage('edit', data.dadata_area_id)}
                    type={'default'}
                    icon={<EditOutlined />}
                />
                <Popconfirm
                    title={'Удаление города'}
                    description={'Вы уверены, что хотите удалить город?'}
                    onConfirm={() =>
                        onEditCoverage('remove', data.dadata_area_id)
                    }
                    okText={'Да'}
                    cancelText={'Нет'}
                >
                    <Button
                        type={'primary'}
                        danger={true}
                        icon={<DeleteOutlined />}
                    />
                </Popconfirm>
            </Space>
        )
    }
]

export default function SettingsAssignment(): ReactElement {
    const [searchParams, setSearchParams] = useSearchParams()
    const pageQuery = searchParams.get('page')
    const pageSizeQuery = searchParams.get('pageSize')

    const page = pageQuery ? parseInt(pageQuery) : 1
    const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : 10

    const dispatch = useDispatch()

    const { data, meta, isFetching, isProcessSynchronizeIds } = useAppSelector(
        settingsAssignmentSelector
    )

    useEffect(() => {
        dispatch(actions.fetch({ page, pageSize }))
    }, [searchParams])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        setSearchParams({
            ...filter,
            ...(pageValue && pageValue !== 1
                ? { page: pageValue.toString() }
                : {}),
            ...(pageSizeValue && pageSizeValue !== 10
                ? { pageSize: pageSizeValue.toString() }
                : {})
        })
    }

    const onEditCoverage = (
        type: 'update' | 'edit' | 'remove',
        dadata_area_id: string
    ) => {
        if (type === 'update') {
            dispatch(actions.synchronize({ dadata_area_id }))
        }
        if (type === 'edit') {
            const findAssignmentItem = data.find(
                item => item.dadata_area_id === dadata_area_id
            )
            if (findAssignmentItem) {
                dispatch(actions.setAssignmentItem(findAssignmentItem))
            }
        }
        if (type === 'remove') {
            dispatch(actions.remove({ dadata_area_id }))
        }
    }

    return (
        <>
            <Row style={{ marginBottom: 20 }} justify={'space-between'}>
                <Title level={3} style={{ margin: 0 }}>
                    {'Присвоение городов'}
                </Title>
                <Button
                    icon={<PlusOutlined />}
                    type={'primary'}
                    onClick={() =>
                        dispatch(actions.toggleModalWizardOpen(true))
                    }
                >
                    {'Присвоить город'}
                </Button>
            </Row>
            <Divider style={{ background: '#ff57a5' }} />
            <Table
                dataSource={data}
                columns={columns(onEditCoverage, isProcessSynchronizeIds)}
                rowKey={'advertising_coverage_id'}
                loading={isFetching}
                pagination={false}
                sticky={{ offsetHeader: 0 }}
                footer={() => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Pagination
                            showQuickJumper
                            current={page}
                            pageSize={pageSize}
                            defaultCurrent={1}
                            defaultPageSize={pageSize}
                            total={meta?.total}
                            onChange={handleChangePagination}
                        />
                    </div>
                )}
            />
            <AssignmentWizardModal />
        </>
    )
}
