import type { AxiosResponse } from 'axios'
import { del, get, patch, post } from '../../utils/api.ts'
import type { CreatePayload, EditPayload, IAssignmentItem } from './types.ts'

export const fetch = (
    page: number,
    page_size: number
): Promise<AxiosResponse<IAssignmentItem[]>> =>
    get<IAssignmentItem[]>('/admin/dadata-areas', {
        page,
        page_size,
        sort: '-created_at',
        include: ['areas']
    })

export const create = (
    form: CreatePayload
): Promise<AxiosResponse<IAssignmentItem>> =>
    post<IAssignmentItem>('/admin/dadata-areas', { ...form })

export const edit = (
    data: EditPayload
): Promise<AxiosResponse<IAssignmentItem>> =>
    patch<IAssignmentItem>(
        `/admin/dadata-areas/${data.dadata_area_id}`,
        data.form
    )

export const remove = (dadata_area_id: string): Promise<AxiosResponse<void>> =>
    del<void>(`/admin/dadata-areas/${dadata_area_id}`)

export const synchronize = (
    dadata_area_id: string
): Promise<AxiosResponse<void>> =>
    post<void>(`/admin/dadata-areas/${dadata_area_id}/sync-entities`)
