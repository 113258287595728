import type {
    ChangeMultipleBlockingPayload,
    ChangeMultiplePublishedPayload,
    FetchPayload
} from './types'
import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { DownloadExcelPayload } from '../Users/types.ts'

export function* handleFetch(action: { payload: FetchPayload }): SagaIterator {
    try {
        const { page, pageSize, filter } = action.payload

        const { data } = yield call(api.fetch, page, pageSize, filter)

        yield put(actions.fetchSuccess({ data: data.data, meta: data.meta }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleChangeMultiplePublished(
    action: PayloadAction<ChangeMultiplePublishedPayload>
): SagaIterator {
    try {
        const { company_ids, published, page, pageSize, filter } =
            action.payload
        yield call(api.changeMultiplePublished, company_ids, published)
        yield put(actions.changeMultiplePublishedSuccess())

        yield put(
            actions.fetch({
                page,
                pageSize,
                filter
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.changeMultiplePublishedFailure({
                error: message
            })
        )
    }
}

export function* watchChangeMultiplePublished(): SagaIterator {
    yield takeEvery(
        actions.changeMultiplePublished,
        handleChangeMultiplePublished
    )
}

export function* handleChangeMultipleBlocked(
    action: PayloadAction<ChangeMultipleBlockingPayload>
): SagaIterator {
    try {
        const { company_ids, blocked, page, pageSize, filter } = action.payload
        yield call(api.changeMultipleBlocked, company_ids, blocked)
        yield put(actions.changeMultipleBlockedSuccess())

        yield put(
            actions.fetch({
                page,
                pageSize,
                filter
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.changeMultipleBlockedFailure({
                error: message
            })
        )
    }
}

export function* watchChangeMultipleBlocked(): SagaIterator {
    yield takeEvery(actions.changeMultipleBlocked, handleChangeMultipleBlocked)
}

export function* handleDownloadExcel(action: {
    payload: DownloadExcelPayload
}): SagaIterator {
    try {
        const { page, pageSize, filter } = action.payload
        yield call(api.downloadExcel, page, pageSize, filter)
        yield put(actions.downloadExcelSuccess())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.downloadExcelFailure({
                error: message
            })
        )
    }
}

export function* watchDownloadExcel(): SagaIterator {
    yield takeEvery(actions.downloadExcel, handleDownloadExcel)
}

export default function* watchCompanies(): SagaIterator {
    yield all([
        fork(watchFetch),
        fork(watchChangeMultiplePublished),
        fork(watchChangeMultipleBlocked),
        fork(watchDownloadExcel)
    ])
}
