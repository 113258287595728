import type {
    InitialStateType,
    CreatePayload,
    CreateSuccessPayload,
    CreateFailurePayload,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload,
    RemovePayload,
    RemoveSuccessPayload,
    RemoveFailurePayload,
    IAssignmentItem,
    EditPayload,
    EditSuccessPayload,
    EditFailurePayload,
    SynchronizePayload,
    SynchronizeSuccessPayload,
    SynchronizeFailurePayload
} from './types.ts'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
const initialState: InitialStateType = {
    data: [],
    meta: null,
    selectedAssignmentItem: null,
    isFetching: false,
    isModalWizardOpen: false,
    isProcessWizard: false,
    isProcessRemove: false,
    isProcessSynchronizeIds: [],
    error: null
}

const settingsAssignmentSlice = createSlice({
    name: 'settingsAssignment',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        setAssignmentItem(
            state: InitialStateType,
            action: PayloadAction<IAssignmentItem>
        ) {
            state.selectedAssignmentItem = action.payload
            state.isModalWizardOpen = true
        },
        toggleModalWizardOpen: (
            state,
            action: PayloadAction<boolean>
        ): void => {
            state.isModalWizardOpen = action.payload
            if (!action.payload) {
                state.selectedAssignmentItem = null
            }
        },
        create(state: InitialStateType, _action: PayloadAction<CreatePayload>) {
            state.isProcessWizard = true
            state.error = null
        },
        createSuccess(
            state: InitialStateType,
            _action: PayloadAction<CreateSuccessPayload>
        ) {
            state.isModalWizardOpen = false
            state.isProcessWizard = false
        },
        createFailure(
            state: InitialStateType,
            action: PayloadAction<CreateFailurePayload>
        ) {
            state.isProcessWizard = false
            state.error = action.payload.error
        },
        synchronize(
            state: InitialStateType,
            action: PayloadAction<SynchronizePayload>
        ) {
            state.isProcessSynchronizeIds.push(action.payload.dadata_area_id)
            state.error = null
        },
        synchronizeSuccess(
            state: InitialStateType,
            action: PayloadAction<SynchronizeSuccessPayload>
        ) {
            state.isProcessSynchronizeIds =
                state.isProcessSynchronizeIds.filter(
                    id => id !== action.payload.dadata_area_id
                )
        },
        synchronizeFailure(
            state: InitialStateType,
            action: PayloadAction<SynchronizeFailurePayload>
        ) {
            state.isProcessSynchronizeIds =
                state.isProcessSynchronizeIds.filter(
                    id => id !== action.payload.dadata_area_id
                )
            state.error = action.payload.error
        },
        edit(state: InitialStateType, _action: PayloadAction<EditPayload>) {
            state.isProcessWizard = true
            state.error = null
        },
        editSuccess(
            state: InitialStateType,
            _action: PayloadAction<EditSuccessPayload>
        ) {
            state.isProcessWizard = false
            state.isModalWizardOpen = false
        },
        editFailure(
            state: InitialStateType,
            action: PayloadAction<EditFailurePayload>
        ) {
            state.isProcessWizard = false
            state.error = action.payload.error
        },
        remove(state: InitialStateType, _action: PayloadAction<RemovePayload>) {
            state.isProcessRemove = true
            state.error = null
        },
        removeSuccess(
            state: InitialStateType,
            _action: PayloadAction<RemoveSuccessPayload>
        ) {
            state.isProcessRemove = false
        },
        removeFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveFailurePayload>
        ) {
            state.isProcessRemove = false
            state.error = action.payload.error
        }
    }
})

export const actions = settingsAssignmentSlice.actions

export default settingsAssignmentSlice.reducer
